import { getToken, returnError } from './helperFunc';
import {
  annualSalesReportActionv2,
  totalDailySalesByMonthAction,
  totalOutletSalesByYearAction,
  totalOutletSalesMonthAction,
  annualBillReportAction,
  billReportMonthAction,
  dailySalesReportAction,
  annualTypeDistributionAction,
  monthTypeDistributionAction,
  annualWeightDistributionAction,
  monthWeightDistributionAction,
  dailySalesWithUtilAction,
  weeklySalesYearAction,
  weeklySalesByMonthAction
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_SALES_LOADING = 'SET_SALES_LOADING';
const CLEAR_SALES_ERROR = 'CLEAR_SALES_ERROR';

const BILL_ANNUAL_SUCCESS = 'BILL_ANNUAL_SUCCESS';
const BILL_ANNUAL_FAIL = 'BILL_ANNUAL_FAIL';
const BILL_MONTH_SUCCESS = 'BILL_MONTH_SUCCESS';
const BILL_MONTH_FAIL = 'BILL_MONTH_FAIL';
const BILL_DAILY_SUCCESS = 'BILL_DAILY_SUCCESS';
const BILL_DAILY_FAIL = 'BILL_DAILY_FAIL';
const ANNUAL_SALES_SUCCESS_V2 = 'ANNUAL_SALES_SUCCESS_V2';
const ANNUAL_SALES_FAIL_V2 = 'ANNUAL_SALES_FAIL_V2';
const DAILY_SALES_SUCCESS = 'DAILY_SALES_SUCCESS';
const DAILY_SALES_FAIL = 'DAILY_SALES_FAIL';
const OUTLETS_SALES_SUCCESS = 'OUTLETS_SALES_SUCCESS';
const OUTLETS_SALES_FAIL = 'OUTLETS_SALES_FAIL';
const OUTLETS_MONTH_SUCCESS = 'OUTLETS_MONTH_SUCCESS';
const OUTLETS_MONTH_FAIL = 'OUTLETS_MONTH_FAIL';
const TYPES_MONTH_SUCCESS = 'TYPES_MONTH_SUCCESS';
const TYPES_MONTH_FAIL = 'TYPES_MONTH_FAIL';
const TYPES_ANNUAL_SUCCESS = 'TYPES_ANNUAL_SUCCESS';
const TYPES_ANNUAL_FAIL = 'TYPES_ANNUAL_FAIL';
const WEIGHT_MONTH_SUCCESS = 'WEIGHT_MONTH_SUCCESS';
const WEIGHT_MONTH_FAIL = 'WEIGHT_MONTH_FAIL';
const WEIGHT_ANNUAL_SUCCESS = 'WEIGHT_ANNUAL_SUCCESS';
const WEIGHT_ANNUAL_FAIL = 'WEIGHT_ANNUAL_FAIL';

const DAILY_SALES_WITH_UTIL_SUCCESS = 'DAILY_SALES_WITH_UTIL_SUCCESS';
const DAILY_SALES_WITH_UTIL_FAIL = 'DAILY_SALES_WITH_UTIL_FAIL';

const WEEKLY_SALES_YEAR_SUCCESS = 'WEEKLY_SALES_YEAR_SUCCESS';
const WEEKLY_SALES_YEAR_FAIL = 'WEEKLY_SALES_YEAR_FAIL';

const WEEKLY_SALES_MONTH_SUCCESS = 'WEEKLY_SALES_MONTH_SUCCESS';
const WEEKLY_SALES_MONTH_FAIL = 'WEEKLY_SALES_MONTH_FAIL';

// FOR SALES PERFORMANCE

export const annualSalesReport = (year) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_SALES_ERROR });
    dispatch({ type: SET_SALES_LOADING });

    const { user } = getState();
    // console.log('user', user);
    const token = getToken(user);

    const { data } = await annualSalesReportActionv2(year, token);

    dispatch({ type: ANNUAL_SALES_SUCCESS_V2, payload: data });
  } catch (error) {
    dispatch({ type: ANNUAL_SALES_FAIL_V2, payload: returnError(error) });
  }
};

export const totalDailySalesByMonth =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });
      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await totalDailySalesByMonthAction(reqBody, token);

      dispatch({ type: DAILY_SALES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: DAILY_SALES_FAIL, payload: returnError(error) });
    }
  };

export const weeklySalesYear = (year, outlets, operatorId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_SALES_ERROR });
    dispatch({ type: SET_SALES_LOADING });

    const { user } = getState();
    const token = getToken(user);

    const { data } = await weeklySalesYearAction(year, outlets, operatorId, token);
    console.log('Data received from backend:', data);

    dispatch({ type: WEEKLY_SALES_YEAR_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: WEEKLY_SALES_YEAR_FAIL, payload: returnError(error) });
  }
};

export const weeklySalesMonth = (postObj) => async (dispatch, getState) => {
  console.log(postObj)
  try {
    dispatch({ type: CLEAR_SALES_ERROR });
    dispatch({ type: SET_SALES_LOADING });

    const { user } = getState();
    const token = getToken(user);

    const { data } = await weeklySalesByMonthAction(postObj, token);
    console.log('Data received from backend:', data);

    dispatch({ type: WEEKLY_SALES_MONTH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: WEEKLY_SALES_MONTH_FAIL, payload: returnError(error) });
  }
};

export const totalOutletSalesByYear =
  (year, operatorId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await totalOutletSalesByYearAction(
        year,
        operatorId,
        token
      );

      dispatch({ type: OUTLETS_SALES_SUCCESS, payload: data });
      // console.log(data.data);
    } catch (error) {
      dispatch({ type: OUTLETS_SALES_FAIL, payload: returnError(error) });
    }
  };

export const totalOutletSalesMonth =
  (year, month, operatorId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await totalOutletSalesMonthAction(
        year,
        month,
        operatorId,
        token
      );

      dispatch({ type: OUTLETS_MONTH_SUCCESS, payload: data });
    } catch (error) {
      // console.log('error', error)
      dispatch({ type: OUTLETS_MONTH_FAIL, payload: returnError(error) });
    }
  };

export const annualBillReport =
  (year, operatorId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await annualBillReportAction(year, operatorId, token);

      dispatch({ type: BILL_ANNUAL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: BILL_ANNUAL_FAIL, payload: returnError(error) });
    }
  };
export const billReportMonth =
  (year, month, operatorId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await billReportMonthAction(
        year,
        month,
        operatorId,
        token
      );

      dispatch({ type: BILL_MONTH_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({ type: BILL_MONTH_FAIL, payload: returnError(error) });
    }
  };

export const dailySalesReport =
  (date, operatorId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await dailySalesReportAction(date, operatorId, token);

      dispatch({ type: BILL_DAILY_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({ type: BILL_DAILY_FAIL, payload: returnError(error) });
    }
  };

export const annualTypeDistribution =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await annualTypeDistributionAction(reqBody, token);

      dispatch({ type: TYPES_ANNUAL_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({ type: TYPES_ANNUAL_SUCCESS, payload: returnError(error) });
    }
  };

export const monthTypeDistribution =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await monthTypeDistributionAction(reqBody, token);

      dispatch({ type: TYPES_MONTH_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({ type: TYPES_MONTH_FAIL, payload: returnError(error) });
    }
  };

export const annualWeightDistribution =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await annualWeightDistributionAction(reqBody, token);

      dispatch({ type: WEIGHT_ANNUAL_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({ type: WEIGHT_ANNUAL_FAIL, payload: returnError(error) });
    }
  };

export const monthWeightDistribution =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await monthWeightDistributionAction(reqBody, token);

      dispatch({ type: WEIGHT_MONTH_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({ type: WEIGHT_MONTH_FAIL, payload: returnError(error) });
    }
  };

export const dailySalesWithUtiReport =
  (date, operatorId, outletSingleId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_SALES_ERROR });
      dispatch({ type: SET_SALES_LOADING });

      const { user } = getState();
      // console.log('user', user);
      const token = getToken(user);

      const { data } = await dailySalesWithUtilAction(
        date,
        operatorId,
        outletSingleId,
        token
      );

      dispatch({ type: DAILY_SALES_WITH_UTIL_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      dispatch({
        type: DAILY_SALES_WITH_UTIL_FAIL,
        payload: returnError(error),
      });
    }
  };

const initialState = {
  language: 'en',
  error: null,
  loading: false,
  annual: null,
  bill: null,
  outletSalesData: null,
  outletMonth: null,
  billAnnual: null,
  billMonth: null,
  salesDaily: null,
  typesAnnual: null,
  typesMonth: null,
  weightAnnual: null,
  weightMonth: null,
  weeklySalesYear: null,
  weeklySalesMonth: null,
};

export const salesReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_SALES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_SALES_ERROR:
      return {
        ...state,
        error: null,
      };

    case ANNUAL_SALES_SUCCESS_V2:
      return {
        ...state,
        annual: action.payload.data,
        loading: false,
        error: null,
      };

    case WEEKLY_SALES_YEAR_SUCCESS:
      return {
        ...state,
        weeklySalesYear: action.payload.data,
        loading: false,
        error: null,
      };

    case WEEKLY_SALES_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case WEEKLY_SALES_MONTH_SUCCESS:
      return {
        ...state,
        weeklySalesMonth: action.payload.data,
        loading: false,
        error: null,
      };

    case WEEKLY_SALES_MONTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DAILY_SALES_SUCCESS:
      return {
        ...state,
        daily: action.payload.data,
        loading: false,
        error: null,
      };

    case OUTLETS_SALES_SUCCESS:
      return {
        ...state,
        outletSalesData: action.payload.data,
        loading: false,
        error: null,
      };

    case OUTLETS_MONTH_SUCCESS:
      return {
        ...state,
        outletMonth: action.payload.data,
        loading: false,
        error: null,
      };

    case BILL_ANNUAL_SUCCESS:
      // console.log('action.payload', action.payload)
      return {
        ...state,
        billAnnual: action.payload.data,
        loading: false,
        error: null,
      };

    case BILL_MONTH_SUCCESS:
      return {
        ...state,
        billMonth: action.payload.data,
        loading: false,
        error: null,
      };

    case BILL_DAILY_SUCCESS:
      return {
        ...state,
        salesDaily: action.payload.data,
        loading: false,
        error: null,
      };

    case TYPES_ANNUAL_SUCCESS:
      return {
        ...state,
        typesAnnual: action.payload.data,
        loading: false,
        error: null,
      };

    case TYPES_MONTH_SUCCESS:
      return {
        ...state,
        typesMonth: action.payload.data,
        loading: false,
        error: null,
      };

    case WEIGHT_ANNUAL_SUCCESS:
      return {
        ...state,
        weightAnnual: action.payload.data,
        loading: false,
        error: null,
      };

    case WEIGHT_MONTH_SUCCESS:
      return {
        ...state,
        weightMonth: action.payload.data,
        loading: false,
        error: null,
      };

    case DAILY_SALES_WITH_UTIL_SUCCESS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        dailySalesWithUtil: action.payload.data,
        highest: action.payload.highest,
        total: action.payload.total,
        loading: false,
        error: null,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    case BILL_ANNUAL_FAIL:
    case BILL_MONTH_FAIL:
    case BILL_DAILY_FAIL:
    case ANNUAL_SALES_FAIL_V2:
    case DAILY_SALES_FAIL:
    case OUTLETS_SALES_FAIL:
    case OUTLETS_MONTH_FAIL:
    case TYPES_ANNUAL_FAIL:
    case DAILY_SALES_WITH_UTIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
